module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Accueil","exclude":["/dev-404-page/","/404/","/page","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/evenements","crumbLabel":"Évènements"},{"pathname":"/actualites","crumbLabel":"Actualités"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Centre Aquatique Provincial","short_name":"Centre Aquatique Provincial","start_url":"/","background_color":"#1e87f0","theme_color":"#1e87f0","display":"minimal-ui","icon":"src/images/favicon-pn.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e93708bfada2c902c80f1ab2372d0ad9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
