// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualites-js": () => import("./../../../src/pages/actualites.js" /* webpackChunkName: "component---src-pages-actualites-js" */),
  "component---src-pages-evenements-js": () => import("./../../../src/pages/evenements.js" /* webpackChunkName: "component---src-pages-evenements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-ss-actu-js": () => import("./../../../src/templates/ss_actu.js" /* webpackChunkName: "component---src-templates-ss-actu-js" */),
  "component---src-templates-ss-event-js": () => import("./../../../src/templates/ss_event.js" /* webpackChunkName: "component---src-templates-ss-event-js" */),
  "component---src-templates-ss-page-js": () => import("./../../../src/templates/ss_page.js" /* webpackChunkName: "component---src-templates-ss-page-js" */)
}

